.search[data-v-6c5fc9ec] {
  grid-column: 1/span 2;
}
.search__input[data-v-6c5fc9ec] {
  appearance: none;
  padding: 10px 8px;
  width: 100%;
  background: #eee;
  border-radius: 2px;
  border: none;
  font-size: 13px;
  color: #302c52;
}
@media screen and (min-width: 768px) {
.search__input[data-v-6c5fc9ec] {
    font-size: 16px;
}
}
