.project-list__item[data-v-6ff0605a] {
  background: #fff;
  text-align: left;
  border-radius: 2px;
  font-size: 14px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 1px 3px 0 rgba(255,255,255,0.16);
  display: flex;
  flex-direction: column;
}
.project-list__item[data-v-6ff0605a]:hover {
  box-shadow: 0 2px 5px 4px rgba(255,255,255,0.16);
}
.project-list__item:hover .project__link[data-v-6ff0605a] {
  color: #6d63a0;
}
.project__image__wrapper[data-v-6ff0605a] {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  border-radius: 2px 2px 0 0;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
}
.project__image__wrapper .project__image__link[data-v-6ff0605a] {
  width: 100%;
}
.project__image[data-v-6ff0605a] {
  width: 101%;
  height: 125px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: auto;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-in;
}
.project__image[data-v-6ff0605a]:hover {
  transform: scale(1.1);
}
.project__image[lazy=loading][data-v-6ff0605a] {
  background-position: center center;
  background-size: 45px;
}
.project__image[lazy=loaded][data-v-6ff0605a] {
  opacity: 1;
  animation: image-loaded-data-v-6ff0605a 0.5s linear 1;
}
.project__link[data-v-6ff0605a] {
  color: #49426d;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
.project__link[data-v-6ff0605a]:hover {
  text-decoration: underline;
}
.project__info[data-v-6ff0605a] {
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 12px;
  line-height: 0;
  color: #24292e;
  background-color: #fff;
  box-shadow: 0 1px 4px 0px rgba(0,0,0,0.25);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0.2em 0.4em;
}
.project__info svg[data-v-6ff0605a] {
  vertical-align: sub;
  width: 11px;
  margin-right: 0.2em;
}
.project__content[data-v-6ff0605a] {
  padding: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}
.project__meta1[data-v-6ff0605a] {
  padding: 20px 15px 10px 15px;
}
.project__meta2[data-v-6ff0605a] {
  min-height: 79px;
  padding: 10px 15px 20px 15px;
  border-top: 1px solid #eee;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 1.4;
}
.project__stars[data-v-6ff0605a],
.project__issues[data-v-6ff0605a] {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}
.project__title[data-v-6ff0605a] {
  display: block;
  margin-bottom: 0.3em;
  font-size: 19px;
  font-weight: 700;
}
.project__description[data-v-6ff0605a] {
  color: #555;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
}
.project__license[data-v-6ff0605a],
.project__latest[data-v-6ff0605a] {
  display: flex;
  justify-content: space-between;
  color: #555;
}
.project__license__value[data-v-6ff0605a] {
  width: 50%;
  text-align: right;
}
@-moz-keyframes image-loaded-data-v-6ff0605a {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
@-webkit-keyframes image-loaded-data-v-6ff0605a {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
@-o-keyframes image-loaded-data-v-6ff0605a {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
@keyframes image-loaded-data-v-6ff0605a {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
