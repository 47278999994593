.main[data-v-2f1d5f34] {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
.main__content[data-v-2f1d5f34] {
  position: relative;
  flex-grow: 1;
  margin-bottom: 5em;
}
.content__info[data-v-2f1d5f34] {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-items: center;
  color: #eee;
  margin-bottom: 2em;
  padding-left: 2em;
  font-size: 12px;
}
.info__sort[data-v-2f1d5f34] {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  align-content: center;
  text-align: center;
}
.sort__label[data-v-2f1d5f34] {
  margin-right: 0.3em;
  text-align: left;
}
.info__search[data-v-2f1d5f34] {
  text-align: left;
  flex-grow: 1;
  font-size: 12px;
}
@media screen and (min-width: 498px) {
.info__search[data-v-2f1d5f34] {
    text-align: right;
}
}
@media screen and (min-width: 786px) {
.main[data-v-2f1d5f34] {
    display: flex;
    align-items: stretch;
}
.footer[data-v-2f1d5f34] {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;
    background-color: rgba(225,193,225,0.08);
    color: #fff;
}
.footer a[data-v-2f1d5f34] {
    color: #fff;
}
.info__search[data-v-2f1d5f34],
  .content__info[data-v-2f1d5f34] {
    font-size: 14px;
}
.content__info[data-v-2f1d5f34] {
    padding-left: 1em;
}
.info__sort[data-v-2f1d5f34] {
    justify-content: flex-start;
}
.sort__label[data-v-2f1d5f34] {
    margin-right: 0.5em;
}
}
