.sort__button[data-v-7bdb6cec] {
  position: relative;
  padding: 0.5em 0.8em 0.5em 0;
  margin-right: 0.3em;
  cursor: pointer;
  user-select: none;
  font-weight: 700;
}
.sort__button[data-v-7bdb6cec]:after {
  position: absolute;
  display: block;
  right: 0;
  font-size: 9px;
  top: 50%;
}
.sort__button--active[data-v-7bdb6cec]:after {
  transform: translateY(-50%) rotate(180deg);
  content: "\25BC";
}
.asc[data-v-7bdb6cec]:after {
  transform: translateY(-35%) rotate(0deg);
}
@media screen and (min-width: 786px) {
.sort__button[data-v-7bdb6cec] {
    margin-right: 0.5em;
}
.sort__button[data-v-7bdb6cec]:after {
    font-size: 10px;
}
}
