.dropdown[data-v-e2b21cc0] {
  text-align: left;
}
.dropdown__title[data-v-e2b21cc0] {
  margin-bottom: 0.5em;
  color: #ced6dc;
  font-size: 13px;
}
.dropdown__select[data-v-e2b21cc0] {
  position: relative;
  display: inline-block;
  width: 100%;
  background: rgba(225,193,225,0.16);
  border-radius: 2px;
}
.dropdown__select select[data-v-e2b21cc0] {
  padding: 10px 8px;
  font-size: 11px;
  color: #ced6dc;
  width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  appearance: none;
}
.dropdown__select select[data-v-e2b21cc0]:focus {
  outline: none;
}
.dropdown__select option[data-v-e2b21cc0] {
  color: #737373;
}
.dropdown__select select[data-v-e2b21cc0]:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #ced6dc;
}
.dropdown__select[data-v-e2b21cc0]:after {
  content: "▼";
  position: absolute;
  right: 5px;
  bottom: 15%;
  font-size: 10px;
  padding: 5px;
  color: #ced6dc;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
.dropdown__title[data-v-e2b21cc0] {
    font-size: 16px;
}
.dropdown__select select[data-v-e2b21cc0] {
    font-size: 14px;
}
.dropdown__select[data-v-e2b21cc0]:after {
    font-size: 12px;
}
}
