.slider[data-v-aa8907f4] {
  padding-bottom: 1em;
}
.slider__title[data-v-aa8907f4] {
  color: #ced6dc;
  text-align: left;
  font-size: 13px;
}
.slider[data-v-aa8907f4] .vue-slider-tooltip {
  font-size: 11px;
}
.slider[data-v-aa8907f4] .vue-slider-tooltip:before {
  display: none;
}
@media screen and (min-width: 768px) {
.slider__title[data-v-aa8907f4] {
    font-size: 16px;
}
.slider[data-v-aa8907f4] .vue-slider-tooltip {
    font-size: 14px;
}
}
