.modal__overlay[data-v-d7de68be] {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
  transition: opacity 0.3s ease;
}
.modal__container[data-v-d7de68be] {
  width: 100%;
  max-width: 500px;
  min-height: 100px;
  transform: translate3d(0, 0, 0);
  padding: 2em 1.5em;
  margin: 20% auto;
  border-radius: 2px;
  background-color: #fff;
}
.close__button[data-v-d7de68be] {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(50%, -50%);
  cursor: pointer;
  box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.1);
}
.close__button[data-v-d7de68be]:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -55%);
  content: 'x';
  font-size: 20px;
  font-weight: 700;
}
.close__button[data-v-d7de68be]:hover {
  color: #3a3a3a;
}
.modal__header[data-v-d7de68be] {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 0.3em;
}
.modal__body[data-v-d7de68be] {
  color: #555;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  line-height: 1.5;
}
.modal__link[data-v-d7de68be] {
  text-decoration: none;
}
.modal__link[data-v-d7de68be]:hover {
  text-decoration: none;
}
.modal__button[data-v-d7de68be] {
  padding: 0.8em 1em;
  background: #2f2c46;
  box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.2);
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  color: #b2b7c5;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: 'Source Code Pro', Helvetica, Arial, sans-serif;
  user-select: none;
  margin: 1.5em auto 0 auto;
  width: 220px;
  display: block;
  transition: background 0.4s, boxShadow 0.4s;
  text-decoration: none;
  outline: none;
}
.modal__button[data-v-d7de68be]:hover {
  background: #5e5984;
  box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.12);
}
