.main_header[data-v-b2c334c8] {
  width: 100%;
  padding: 0.4em 0 1em 0;
  margin: 0 auto;
  background-color: rgba(225,193,225,0.08);
}
.main_header__content[data-v-b2c334c8] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.main_header__logo[data-v-b2c334c8] {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  text-align: left;
}
.logo__image[data-v-b2c334c8] {
  width: 50px;
  align-self: baseline;
  margin-right: 5px;
}
.logo__headline[data-v-b2c334c8] {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 4px rgba(255,255,255,0.5);
}
.logo__subheadline[data-v-b2c334c8] {
  color: #e6e6e6;
  font-size: 12px;
}
.main_header__buttons[data-v-b2c334c8] {
  display: none;
  justify-content: flex-end;
  align-items: center;
  margin-top: 13px;
}
.button__submit-new[data-v-b2c334c8] {
  padding: 0.4em 1em;
  background: rgba(225,193,225,0.16);
  box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.2);
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  color: #b2b7c5;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: 'Source Code Pro', Helvetica, Arial, sans-serif;
  user-select: none;
}
.button__submit-new[data-v-b2c334c8]:hover {
  box-shadow: 0px 0px 4px 1px rgba(255,255,255,0.2);
}
.button__submit-new[data-v-b2c334c8]:focus {
  outline: none;
}
.button__submit-new[data-v-b2c334c8]:active {
  background: #38345d;
}
@media screen and (min-width: 768px) {
.logo__headline[data-v-b2c334c8] {
    font-size: 26px;
}
.logo__subheadline[data-v-b2c334c8] {
    font-size: 15px;
}
.logo__image[data-v-b2c334c8] {
    width: 70px;
}
.main_header__buttons[data-v-b2c334c8] {
    display: flex;
}
}
