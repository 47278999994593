.filterList[data-v-25d85da7] {
  position: relative;
  z-index: 10;
}
.filterList__inner[data-v-25d85da7] {
  padding: 0;
}
.filterList__item[data-v-25d85da7] {
  margin: 1em 0;
}
@media screen and (min-width: 414px) {
.filterList__inner[data-v-25d85da7] {
    padding: 1em 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em 2em;
    align-items: center;
}
.filterList__item[data-v-25d85da7] {
    margin: 0;
}
}
@media screen and (min-width: 768px) {
.filterList__inner[data-v-25d85da7] {
    grid-template-columns: repeat(4, 1fr);
}
}
